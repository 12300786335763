import React from "react"
import { graphql } from 'gatsby'
import Prose from "../components/utility/prose"

import Seo from "../components/seo"
import Content from "../components/content"
import PageLocales from "../components/utility/page-locales"

const Page = ({data, pageContext }) => {
  const page = data.strapiPage
  const content = page.content
  return (
  <>
    <Seo title={page.title} />
    <Prose>
      <h1 className="mb-14">{page.title}</h1>
      <PageLocales locale={page.locale} localizations={page.localizations} slug={pageContext.slug} />
    </Prose>
    <Content content={content}></Content>
  </>
)}

export default Page


export const pageQuery = graphql`
  query pageById($id: Int!) {
    strapiPage(strapi_id: { eq: $id }) {
      id
      title
      locale
      localizations {
        data {
          id
          attributes {
            locale
          }
        }
      }
      content {
        ... on STRAPI__COMPONENT_PAGE_ALERT {
          ...pageAlert
        }
        ... on STRAPI__COMPONENT_PAGE_CARDS {
          ...pageCards
        }
        ... on STRAPI__COMPONENT_PAGE_GALLERY {
          ...pageGallery
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE {
          ...pageImage
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE_TEXT {
          ...pageImageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT {
          ...pageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_IMAGE {
          ...pageTextImage
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_TEXT {
          ...pageTextText
        }
        ... on STRAPI__COMPONENT_PAGE_WIDGET {
          ...pageWidget
        }
      }
    }
  }
`
